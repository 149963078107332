import styled from 'styled-components'
import { xl, lg, md, sm, xll } from 'config/variables'

export const GraphicsrrenderingsecStyles = styled.div`
margin-bottom:118px;
${xl(`
    margin-bottom:100px;
`)}
${lg(`
    margin-bottom:60px;
`)}
${md(`
    margin-bottom:40px;
`)}
.content-list-wrap {
    display: flex;
    justify-content: flex-end;
    .content-list {
      flex: 0 1 867px;
      ${xl(`
        flex: 0 1 821px;
     `)}
     ${lg(`
        flex: 0 1 536px;
     `)}
     ${md(`
        flex:1 1 auto;
     `)}
    .img-frame{
        width: 100%;
        margin: 77px 0 66px;
        position: relative;
        user-select: none;
        ${xl(`
            max-width:821px;
            margin:77px auto 66px ;
        `)} 
        ${lg(`
            max-width:536px;
            margin:77px auto 52px;
        `)} 
        ${md(`
            max-width:439px;
            margin: 30px auto;
        `)} 
        ${sm(`
            max-width:280px;
            margin: 30px auto;
        `)} 
        .wrapper-img{
            width:calc(100% - 24px);
            height: calc(100% - 128px);
            position: absolute;
            top: 12px;
            left: 12px;
            ${xl(`
              top: 11px;
              width:calc(100% - 22px);
              height: calc(100% - 121px);
            left: 11px;
            `)} 
             ${lg(`
                top: 7px;
                width: calc(100% - 14px);
                height: calc(100% - 80px);
                left: 7px;
            `)} 
             ${md(`
                top: 6px;
                width: calc(100% - 12px);
                height: calc(100% - 67px);
                left: 6px;
            `)} 
            ${sm(`
                   top: 3px;
                    width: calc(100% - 8px);
                    height: calc(100% - 43px);
                    left: 4px;
            `)} 
            .after, .before{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            overflow: hidden;
            user-select: none;
                img{
                    height: 100%;
                    max-width: min-content;
                    pointer-events: none;
                }
            }
            .scroller{
            background: #35C13C;
            width: 10px;
            height: calc(100% + 61px);
            border-radius: 5px;
            position: absolute;
            top: -30px;
            cursor: pointer;
            pointer-events: auto;
            ${md(`
                top: -20px;
                height: calc(100% + 40px);
                width:5px;
            `)} 
            ${sm(`
                width:5px;
            `)} 
            }
        }
     }
   }
}
`
