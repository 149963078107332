import styled from 'styled-components'
import { xl, md, lg, device } from 'config/variables'
import { images } from 'config/images'

export const InteriordesigningsecStyles = styled.div`
margin-bottom:50px;
${xl(`
      margin-bottom: 40px;
`)}
${lg(`
      margin-bottom: 40px;
`)}
${md(`
      margin-bottom: 20px;
`)}
.content-container {
    max-width: 867px;
    margin: 0 auto;
    margin-bottom: 72px;
    ${xl(`
      margin-bottom: 70px;
  `)}
    ${lg(`
      margin-bottom: 65px;
  `)} 
    ${md(`
      margin-bottom: 40px;
  `)} 
}
.row {
    display: flex;
    justify-content: space-between;
    ${md(`
        flex-direction:column;
     `)}
    .img-blk {
      flex: 0 1 590px;
      ${md(`
        flex:1 1 auto;
     `)}
      &:first-child {
        padding: 0 30px 0 0;
        ${lg(`
            padding-right:18px;
        `)}
        ${md(`
            padding:0;
        `)}
      }
      &:last-child {
        padding: 235px 0px 0 30px;
       ${lg(`
          padding-top: 150px;
          padding-left:18px;
      `)}
        ${md(`
            padding:0;
        `)}
      }
      
      .designingImagesThumb {
          margin-bottom:60px;
          position: relative;
          ${lg(`
              margin-bottom:35px;
          `)}
            ${md(`
              margin-bottom:20px;
          `)}
          .designingImages-bg {
            position: absolute;
            left: 0;
            top:0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: #ebe0ff;
            background-position: 0px 0px;
            background-image: url(${images.paternBackground});
            background-size: 24px;
            opacity: 1;
            transition: all 0.3s;
            opacity:0;
            
          }
          &:hover {
            .designingImages-bg {
            opacity:1;
            left: 15px;
            top: -15px;
            width: calc(100% - 30px);
            height: calc(100% + 30px);
            transition: all 0.3s;
            ${md(`

                left: 8px;
                top: -8px;
                width: calc(100% - 16px);
                height: calc(100% + 16px);
            `)}
            }
      }
      video {
        width:100%;
      }
    }
  }
`
