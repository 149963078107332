import React from 'react'
import { InteractiveappsecStyles } from './Interactiveappsec.styles'
import Image from 'components/ImgOptimized'

function Interactiveappsec(props) {
  const { InteractiveappProps } = props.Interactiveappdata
  const {
    data: {
      InteractiveappImages: {
        childImageSharp: { fluid: InteractiveappImages },
      },
    },
  } = props
  return (
    <InteractiveappsecStyles>
      <div className="container">
        <h2>{InteractiveappProps.heading}</h2>
        <div className="content-list-wrap">
          <div className="content-list">
            <p>{InteractiveappProps.topPara}</p>
            <figure>
              <Image
                fluid={InteractiveappImages}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 694,
                }}
              />
              <div className="img-caption">
                {InteractiveappProps.mainImgcaption}
              </div>
            </figure>
            <p>{InteractiveappProps.bottomPara}</p>
          </div>
        </div>
      </div>
    </InteractiveappsecStyles>
  )
}

export default Interactiveappsec
