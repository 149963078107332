import React, { useRef } from 'react'
// import { VideoplayhoverStyles } from './Videoplayhover.styles'

const Videoplayhover = ({ videoURL }) => {
  const videoRef = useRef()

  const playVideo = () => {
    videoRef.current.play()
  }

  const pauseVideo = () => {
    videoRef.current.pause()
  }

  return (
    <div>
      <video
        ref={videoRef}
        muted
        loop
        playsInline
        onMouseEnter={playVideo}
        onMouseLeave={pauseVideo}
      >
        <source src={videoURL} type="video/mp4" />
      </video>
    </div>
  )
}

export default Videoplayhover
