import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { decoraSystemsProps } from 'components/Props/decora-systems'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Interiordesigningsec from 'components/Interiordesigningsec/Interiordesigningsec'
import Graphicsrrenderingsec from 'components/Graphicsrrenderingsec/Graphicsrrenderingsec'
import Interactiveappsec from 'components/Interactiveappsec/Interactiveappsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import { lg } from 'config/variables'

export const DecoraSystemsPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #b1ac95;
          }
        }
      }
    }
  }
  .caseintro-section {
    margin-bottom: 30px;
    ${lg(`
          margin-bottom: 20px;
    `)}
  }
`

const DecoraSystems = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Decora Systems"
        description="Decora Systems collaborated with Simform to create SaaS-based unique 3D interior designing tool with VR capabilities for interior designers."
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <DecoraSystemsPage>
        <Casebanner
          bannerdata={decoraSystemsProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={decoraSystemsProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Caseresultsec
            caseresultsecdata={decoraSystemsProps}
            {...props}
            showImages={false}
          />
          <Interiordesigningsec
            Interiordesigningdata={decoraSystemsProps}
            {...props}
          />
        </div>
        {showContent && (
          <>
            <Graphicsrrenderingsec
              graphicsrrenderingdata={decoraSystemsProps}
            />
            <Interactiveappsec
              Interactiveappdata={decoraSystemsProps}
              {...props}
            />
            <Relatedcaselist
              relatedcaselistdata={decoraSystemsProps}
              {...props}
            />
            <Casecontactcardinfo Casecontactcardinfodata={decoraSystemsProps} />
          </>
        )}
      </DecoraSystemsPage>
    </Layout>
  )
})

export default DecoraSystems
export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/decora-systems-banner@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    InteractiveappImages: file(
      relativePath: { regex: "/interactive-app-images@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1190) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/sentmap-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/ihealth-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(relativePath: { regex: "/insuranceDetails@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
