import styled from 'styled-components'
import { xl, lg, md } from 'config/variables'

export const InteractiveappsecStyles = styled.div`
margin-bottom:110px;
    ${xl(`
        margin-bottom:90px;
    `)}
    ${lg(`
    margin-bottom:60px;
    `)}
    ${md(`
    margin-bottom:40px;
    `)}
 .content-list-wrap {
    display: flex;
    justify-content: flex-end;
    .content-list {
      flex: 0 1 867px;
      ${xl(`
        flex: 0 1 821px;
     `)}
     ${lg(`
        flex: 0 1 536px;
     `)}
     ${md(`
        flex:1 1 auto;
     `)}
    }
 }
`
