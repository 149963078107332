import React from 'react'
import { images } from 'config/images'

export const decoraSystemsProps = {
  bannerProps: {
    heading: (
      <>
        Decora <br />
        Systems
      </>
    ),
    bannerPara: 'Meaningful innovation to drive Interior Designing virtually',
    BannerAlt: 'Decora Systems Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Interior designing takes innumerable things into account. This makes it
        tiresome and time consuming process. Hence, a digital platform which
        gives the facility to try and test the design ideas before actual
        implementation was the need of the hour. Decora Systems is an easy to
        use tool for interior designers which provides a premium experience to
        them so they can be confident in their choice of interiors of their
        space. By harnessing the power of Web and VR technology, Decora studio
        allows users to create virtual spaces using custom dimensions, colors,
        flooring, items/products offered by major companies associated with
        interior solutions.
      </>
    ),
    subheading: 'Building a Prototype for Better User Reviews',
    paraLeft:
      'Currently, the interior design market lacks in the offering of platforms where professional or non-professional user can envision design their ideas. There are few apps that help you design your space with features like coloring, adding basic furniture and 2D view but that’s pretty basic. There is a latent need of interior designing software, where anyone can design their own virtual space with premium features, ease of use and 3D rendering capabilities; whether it’s an individual room, office space, or an entire house.',
    paraRight:
      '‘Decora Systems’ partnered with Simform to create a unique 3D interior designing tool with VR capabilities. We started with a prototype to check the feasibility of application and see responses from interior designers. After successfully testing the prototype, we started building a full fledged web-app which is handy and easy-to-use and can run on any browser irrespective of computing and graphics power of the machine or laptop. ',
  },
  casereSultsecProps: {
    heading: 'Result',
    RightSubHead: 'Virtual Reality and 3D visualization software',
    maincontent: (
      <>
        We built a SaaS based platform for online 3D interior designing where
        user can just subscribe and start using it to design interiors which
        includes colors, customized furniture, art-works, lightings et al. The
        application can also be used on ‘Kiosk’ in showrooms by retailers or
        manufacturers to showcase their latest design.
        <br /> <br />
        Apart from 3D designing and viewing, it also provides visualising
        infrastructure, interior designs, products and architecture in VR. The
        product catalogue can be easily renewed now and retailers are able to
        sell their ideas on the spot. More than 10000 models are created using
        the tool and hundreds of companies are using it to coach their employees
        using virtual designs of their product.
      </>
    ),
    videoURL: images.decoraStudioVideo,
  },
  InteriordesigningProps: {
    heading: 'Web application for hassle-free Interior Designing',

    para: (
      <>
        When it comes to selecting the furniture or artwork, there are millions
        of patterns and designs, which makes it impossible for anyone to make-up
        their mind just by looking at the brochures. Decora system provides the
        platform for professional as well as non-professional users to design
        everything right from floor, wall texture to fixtures in 3D using simple
        drag & drop tool.
        <br /> <br />
        Decora provides ‘Code-less’ environment to add new contents like colors,
        furniture items, artworks, lights, textures into the application. This
        provides flexibility to store owners or showrooms to add their latest
        designs periodically without any tech help.
      </>
    ),
    Interiordesigninglists: [
      [
        {
          VideoLink: images.floorPlanner,
        },
        {
          VideoLink: images.furnishingDesign,
        },
      ],
      [
        {
          VideoLink: images.texturesColors,
        },
        {
          VideoLink: images.lightFinishes,
        },
      ],
    ],
  },
  GraphicsrrenderingProps: {
    heading: '3D graphics rendering to create real-world like effects',
    topPara:
      'The whole concept and success of this project is dependent on rendering a photo-realistic image instead of an animated image of the space that is designed by the user, so that they can relate it to the real-world scenario. While designing this app, our team focused on creating and designing smooth 3D rendering UI, which ensures the prime user experience.',
    bottomPara: (
      <>
        We used WebGL along with Babylon.JS for rendering interactive 3D
        graphics on the web application. This ensures smooth VR and 3D rendering
        without any 3rd party plug-ins on the web-browser. Babylon.JS also
        provides a more targeted approach and it supports complete scenelights,
        cameras, materials, layout et al. <br />
        <br />
        This platform allows users to visualize products, surface, architecture
        through VR, after the content is loaded in the code-less environment. It
        supports VR headsets like Oculus, Vive, Sony PS, Google cardboard et al.
      </>
    ),
  },
  InteractiveappProps: {
    heading: 'Sustainable and Interactive App using Python',
    topPara:
      'The size of the project was huge, however, we wanted to keep the turnaround time as low as possible. Hence, we leveraged the power of python. Python provides different libraries to develop back-end for interactive and graphically heavy apps, inshort more can be achieved using less code by using Python.',
    bottomPara: (
      <>
        Dividing development into sprints, it allowed us to perform rapid
        testing and scaling of most complex parts very easy. Implementing WebGL
        along with Python provides tremendous capabilities and a truly
        impressive rendering engine to view real life objects.
      </>
    ),
    mainImgcaption:
      'Decora system architecture with front end and backend services',
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'SentMap',
        para: 'Sentiment based market analytics engine for portfolio managers',
        className: 'pale-blue',
        link: '/sentiment-market-analytics-engine/',
      },
      {
        title: 'iHealth',
        para:
          'Clinically validated health monitoring and fitness coaching solution',
        className: 'soft-pink-two',
        link: '/ihealth-monitoring-platform/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
