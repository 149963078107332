import React from 'react'
import { InteriordesigningsecStyles } from './Interiordesigningsec.styles'
import Videoplayhover from 'components/Videoplayhover/Videoplayhover'

function Interiordesigningsec(props) {
  const { InteriordesigningProps } = props.Interiordesigningdata
  return (
    <InteriordesigningsecStyles>
      <div className="container">
        <div className="content-container">
          <h2>{InteriordesigningProps.heading}</h2>
          <p>{InteriordesigningProps.para}</p>
        </div>
        <div className="row">
          {InteriordesigningProps.Interiordesigninglists.map(
            (InteriordesigningProp, i) => {
              return (
                <div key={i} className="img-blk">
                  {InteriordesigningProp.map((InteriordesigningImages, i) => {
                    return (
                      <div key={`${i}${i}`} className="designingImagesThumb">
                        <div className="designingImages-bg back-animate" />
                        <Videoplayhover
                          videoURL={InteriordesigningImages.VideoLink}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            }
          )}
        </div>
      </div>
    </InteriordesigningsecStyles>
  )
}

export default Interiordesigningsec
