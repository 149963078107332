import React, { useRef, useEffect } from 'react'
import { GraphicsrrenderingsecStyles } from './Graphicsrrenderingsec.styles'
import { imgSlider } from 'components/before-after-imgSlider'
import { images } from 'config/images'

function Graphicsrrenderingsec(props) {
  const { GraphicsrrenderingProps } = props.graphicsrrenderingdata

  const wrapperRefs = useRef()
  useEffect(() => {
    if (wrapperRefs.current) {
      imgSlider()
    }
  }, [])
  return (
    <GraphicsrrenderingsecStyles>
      <div className="container">
        <h2>{GraphicsrrenderingProps.heading}</h2>
        <div className="content-list-wrap">
          <div className="content-list">
            <p>{GraphicsrrenderingProps.topPara}</p>
            <div className="img-frame">
              <img src={images.desktopScreen} alt="" />
              <div ref={wrapperRefs} className="wrapper-img">
                <div className="before">
                  <img src={images.renderingNew} alt="" />
                </div>
                <div className="after">
                  <img src={images.renderingOld} alt="" />
                </div>
                <div className="scroller"></div>
              </div>
            </div>
            <p>{GraphicsrrenderingProps.bottomPara}</p>
          </div>
        </div>
      </div>
    </GraphicsrrenderingsecStyles>
  )
}

export default Graphicsrrenderingsec
